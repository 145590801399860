import React, { useState } from 'react'
import { 
    Navbar, NavbarBrand, NavbarToggler, Collapse, 
    Nav, NavItem, NavLink, Button
} from 'reactstrap'
 import logo from '../../assets/images/logo.png'

const CustomNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [active, setActive] = useState('home')

    const setActiveColor = (name) => {
        setActive(name)
    }

    return(
        <Navbar
            color="white"
            expand="lg"
            light
            fixed
        >
            <div className='navbar-main'>
                <NavbarBrand href="/" className='navbar-brand'>
                    <img 
                        src={logo}
                        alt='pacdoc-sign'
                        className='navbar-logo'
                    />
                    <div className='navbar-tagline'>
                        Managed Signing Solutions
                        <br />
                        Nationwide Since 1990
                    </div>
                </NavbarBrand>
                <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
                <Collapse isOpen={isOpen} navbar className='nav-collapse'>
                    <Nav
                        className="me-auto"
                        navbar
                    >
                        <div className='nav-display'>
                            <div className='items'>
                                <NavItem className='navItem' onClick={() => setActiveColor('home')}>
                                    <NavLink href="#home" style={{color: active === 'home' ? '#3361a2' : '#6f6f6f'}}>
                                        HOME
                                    </NavLink>
                                </NavItem>
                                <NavItem className='navItem' onClick={() => setActiveColor('about')}>
                                    <NavLink href="#about" style={{color: active === 'about' ? '#3361a2' : '#6f6f6f'}}>
                                        ABOUT
                                    </NavLink>
                                </NavItem>
                                <NavItem className='navItem' onClick={() => setActiveColor('ourServices')}>
                                    <NavLink href="#our-services" style={{color: active === 'ourServices' ? '#3361a2' : '#6f6f6f'}}>
                                        OUR SERVICES
                                    </NavLink>
                                </NavItem>
                                <NavItem className='navItem' onClick={() => setActiveColor('partners')}>
                                    <NavLink href="#partners" style={{color: active === 'partners' ? '#3361a2' : '#6f6f6f'}}>
                                        PARTNERS
                                    </NavLink>
                                </NavItem>
                                <NavItem className='navItem' onClick={() => setActiveColor('contactUs')}>
                                    <NavLink href="#contact-us" style={{color: active === 'contactUs' ? '#3361a2' : '#6f6f6f'}}>
                                        CONTACT US
                                    </NavLink>
                                </NavItem>
                                <NavItem className='navBtn'>
                                    <NavLink href="https://clients.pacdocsign.com/login">
                                        <Button style={{marginLeft: 15}} className='navbar-button'>Client Login</Button>
                                    </NavLink>
                                </NavItem>
                                <NavItem className='navBtn'>
                                    <NavLink href="https://signers.pacdocsign.com/">
                                        <Button className='navbar-button'>Signing Agent Login</Button>
                                    </NavLink>
                                </NavItem>
                                <NavItem className='navItem'>
                                    <h1 className='nav-telephone'>TEL : 800-884-7587</h1>
                                    <NavLink href="mailto: info@pacdocsign.com">
                                        <p className='nav-email'>
                                            Email: info@pacdocsign.com
                                        </p>
                                    </NavLink>
                                </NavItem>
                            </div>
                        </div>
                    </Nav>
                </Collapse>
            </div>
        </Navbar>
    )
}

export default CustomNavbar