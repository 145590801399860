import React from 'react'
import CustomNavbar from '../components/common/Navbar';
import CustomCarousel from '../components/common/Carousel';
import About from '../components/About';
import WhatWeDo from '../components/WhatWeDo';
import Partners from '../components/Partners';
import ContactUs from '../components/ContactUs';
import CustomFooter from '../components/common/Footer';

const Main = () => {
  return (
    <div>
       <CustomNavbar />
      <CustomCarousel />
      <About />
      <WhatWeDo />
      <Partners />
      <ContactUs />
      <CustomFooter />
    </div>
  );
}

export default Main;
