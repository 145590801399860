import Main from './pages/Index'
import './assets/styles/navbar.css'
import './assets/styles/common.css'
import './assets/styles/contactUs.css'
import './assets/styles/footer.css'

const App = () => {
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
