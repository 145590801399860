import React, { useState } from 'react'
import { 
    Carousel, CarouselIndicators, CarouselItem, CarouselControl,
 } from 'reactstrap';
import banner1 from '../../assets/images/banner-1-new.jpg';
import banner2 from '../../assets/images/banner-2-new.jpg';
import banner3 from '../../assets/images/banner-3-new.jpg';

const CustomCarousel = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const items = [
        {
            src: `${banner1}`,
            altText: 'Banner One'
        },
        {
            src: `${banner2}`,
            altText: 'Banner Two'
        },
        {
            src: `${banner3}`,
            altText: 'Banner Three'
        },
    ];
  
    const itemLength = items.length - 1

    const previousButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ?
            itemLength : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
  
    // Next button for Carousel
    const nextButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === itemLength ?
            0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
  
    const carouselItemData = items.map((item) => {
        return (
            <CarouselItem
                key={item.src}
                onExited={() => setAnimating(false)}
                onExiting={() => setAnimating(true)}
            >
                <img src={item.src} alt={item.altText} className='banner-carousel'/>
            </CarouselItem>
        );
    });
  
    return (
        <section id='home'>
            <Carousel previous={previousButton} next={nextButton} interval={null}
                activeIndex={activeIndex}>
                <CarouselIndicators items={items}
                    activeIndex={activeIndex}
                    onClickHandler={(newIndex) => {
                        if (animating) return;
                        setActiveIndex(newIndex);
                    }} />
                {carouselItemData}
                <CarouselControl directionText="Prev"
                    direction="prev" onClickHandler={previousButton} />
                <CarouselControl directionText="Next"
                    direction="next" onClickHandler={nextButton} />
            </Carousel>
        </section >
    )
}

export default CustomCarousel