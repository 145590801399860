import React from 'react'
import {
    Row,
    Col
} from 'reactstrap'
 import Header from './common/Header'

const ContactUs = () => {

    return (
        <section id='contact-us'>
            <Header name='Contact Us' />
            <div className='section-body contact-us'>
                <div className='container'>
                        <Row className='container contact-content'>
                            <Col md="4" sm="4" xs="12" className='mt-4 mb-4 contact-card'>
                                <center>
                                    <a href="https://www.google.co.in/maps/place/10801+National+Blvd+%23510,+Los+Angeles,+CA+90064,+USA/@34.0238066,-118.4109498,14z/data=!4m5!3m4!1s0x80c2bbb6e167cd1b:0x34180f252aa9db2a!8m2!3d34.0312312!4d-118.4203912">
                                        <div className='container-icon'>
                                            <i className='fa fa-map-marker contact-icons-original blue-text' />
                                            <div className="overlay-icon">
                                                <i className='fa fa-map-marker contact-icons white-text' />
                                            </div>
                                        </div>
                                        {/* <div className="location-img img-frame"></div> */}
                                        <p className='contact-text black-text mt-4'>
                                            10801 National Blvd. Suite 510<br/>
                                            Los Angeles, CA 90064
                                        </p>
                                    </a>
                                </center>
                            </Col>
                            <Col md="4" sm="4" xs="12" className='mt-4 mb-4 contact-card'>
                                <center>
                                    <a href="tel:+800-884-7587">
                                        <div className='container-icon'>
                                            <i className='fa fa-phone contact-icons contact-icons-original blue-text' />
                                            <div className="overlay-icon">
                                                <i className='fa fa-phone contact-icons white-text' />
                                            </div>
                                        </div>
                                        {/* <div className="phone-img img-frame"></div> */}
                                        <p className='contact-text black-text mt-4'>
                                            800-884-7587
                                        </p>
                                    </a>
                                </center>
                            </Col>
                            <Col md="4" sm="4" xs="12" className='mt-4 mb-4 contact-card'>
                                <center>
                                    <a href="mailto:info@PacDocSign.com">
                                        <div className='container-icon'>
                                            <i className='fa fa-envelope contact-icons contact-icons-original blue-text' />
                                            <div className="overlay-icon">
                                                <i className='fa fa-envelope contact-icons white-text' />
                                            </div>
                                        </div>
                                        {/* <div class="mail-img img-frame"></div> */}
                                        <p className='contact-text black-text mt-4'>
                                            info@PacDocSign.com
                                        </p>
                                    </a>
                                </center>
                            </Col>
                        </Row>
                    </div>
                </div>
        </section>
    )
}

export default ContactUs