import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Spinner
} from 'reactstrap'
import Header from './common/Header';
import accuracy from '../assets/images/accuracy.PNG';
import compliance from '../assets/images/compliance.PNG';
import nationwide from '../assets/images/nationwide.PNG';
import security from '../assets/images/security.PNG';
import staff from '../assets/images/staff.PNG';
import technology from '../assets/images/technology.PNG';

const WhatWeDo = () => {
    let [display, setDisplay] = useState(0);
    let [content, setContent] = useState(display);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(display > 0) {
            setContent(display - 1);
        }
    }, [display])

    const setBack = () => {
        setDisplay(0)
    }

    const setCardContent = (value) => {
        if(!loading) {
            setLoading(true);
            setDisplay(value);
            setContent(value-1)
            console.log(display)
            let timer = setTimeout(() => 
                setLoading(false),
            2000);
            console.log(display)
            return () => {
                clearTimeout(timer);
            };
            }
    }

    const cardDetails = [
        {
            id: 1,
            name: 'ACCURACY',
            body: <p>
                    PacDocSign prides itself in having one of the lowest loan signing error ratio in the industry. 
                    Our industry experienced staff enforce a solid business process regulated by custom created software
                    based on the latest technology to organize and deliver accurate results. We review and carefully
                    look at every client profile and create goals in improving both service and accuracy on a
                    regular interval.
                   </p>,
            image: <img src={accuracy} alt='accuracy' />
        },
        {
            id: 2,
            name: 'COMPLIANCE',
            body: <p>
                    PacDocSign maintains a $1,000,000 E&amp;O insurance that covers our entire data base of Signing 
                    Agents. We fully understand the security and confidentially of your borrower personal information.
                    We comply with all Guidelines of:<br/>
                    -GLBA (Gramm–Leach–Bliley Act)<br/>
                    -SPW (Signing Professionals Workgroup)<br/>
                    -CFPB (Consumer Financial Protection Bureau 
                  </p>,
            image: <img src={compliance} alt='compliance' />
                    
        },
        {
            id: 3,
            name: 'NATIONWIDE',
            body: <p>
                    PacDocsign is a nationwide loan document signing company that will help facilitate all your
                     document signing needs. Through a network of thousands of qualified signing agents and 
                     attorneys, we coordinate and simplify the process of closing mortgage transactions. We 
                     provide our services through qualified signing agents including bilingual, background 
                     checked and experienced notaries across the nation.
                  </p>,
            image: <img src={nationwide} alt='nationwide' />      
        },
        {
            id: 4,
            name: 'SECURITY',
            body: <p>
                    PacDocSign uses 128-bit Secure Sockets Layer (SSL) technology. The data transferred between 
                    PacDocSign’s Web Applications and users are encrypted and cannot be viewed by any malicious 3rd 
                    party. The security of borrower’s personal information is of the utmost importance to PacDocSign.
                  </p>,
            image: <img src={security} alt='security' />
                    
        },
        {
            id: 5,
            name: 'STAFF',
            body: <p>
                    With over 2 decades of industry experience,our company has trained our in-house staff to be 
                    knowledgeable, efficient, thorough to best meet our client’s expectations for every signing. 
                    Our staff is available around the clock and will easily integrate with any workflow to meet 
                    client’s needs.
                  </p>,
            image: <img src={staff} alt='staff' />
        },
        {
            id: 6,
            name: 'TECHNOLOGY',
            body: <p>
                    PacDocSign’s custom software was built from the ground up to cater to our client’s specific 
                    communication needs. The Client Area Portal allows the client to create new orders, upload 
                    documents, monitor order status, and communicate with PacDocSign from the portal. Client’s 
                    also have the option to directly integrate to our system via XML technology to avoid double 
                    entry of orders. The Signer Area Portal allows signers to communicate and provide status on 
                    signings, download documents, and manage invoices.
                   </p>,
            image: <img src={technology} alt='technology' />
        }
    ]

    return (
        <section  id='our-services'>
            <Header name='What we do' />
            <div className='section-body'>
                <div className='container'>
                    <div className='inner-2'>
                        {display === 0 ? 
                            <>
                                <p>
                                    At PacDocSign we are dedicated to providing our clients with the most streamlined and effective loan 
                                    document signing services. Our company, which launched as a notary service, has now grown to match the 
                                    versatile needs of our esteemed clients. PacDocSign has been finding innovative solutions to mortgage 
                                    document signing for over 24 years. We have gone above and beyond to create one of the leading document 
                                    signing ‘systems’ that eliminates the hassle of unorganized and archaic signing procedures.
                                </p>
                                <p>
                                    PacDocSign is known nationwide for its dependability, flexibility, and outstanding customer service. 
                                    We are proud to give our clients full use of our extensive database of signing agents from across the 
                                    country. Our database includes experienced notaries and attorneys for witness only signings, which are 
                                    at your disposal during any point of the signing process.
                                </p>
                                <br/>
                                <Row className='container'>
                                    {cardDetails && cardDetails.map((data, index) => (
                                        <Col xl="4" md="3" sm="6" xs="12" className='mt-1 mb-1' key={index}>
                                            <div className="container-hover">  
                                                {data.image}
                                                <div className="overlay-1">
                                                    <div onClick={() => setCardContent(data.id)}>
                                                        <div className="para">
                                                            <h4 className='grey-text'>{data.name}</h4>
                                                            <p className="grey-text">
                                                            {data.body}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                    }
                                </Row>
                            </>
                            :
                            loading === true && display !== 0 ?
                                <div className='card-detail'>
                                    <center>
                                        <Spinner animation="border" variant="primary" />
                                    </center>
                                </div>
                            :
                            <>
                                <div className='detail-buttons'>
                                    <Button className='card-btn' onClick={() => setBack()}>Back</Button>
                                    <div className='detail-btn'>
                                        <Button 
                                            className='card-btn'
                                            onClick={() => setCardContent(display - 1)}
                                            disabled={(display === 1 ? true : false) || loading}
                                        >
                                            Prev
                                        </Button>
                                        <Button 
                                            className='card-btn' 
                                            onClick={() => setCardContent(display + 1)}
                                            disabled={(display === 6 ? true : false) || loading}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                                {cardDetails && cardDetails[content] &&
                                    <div className='card-detail'>
                                        <h3>{cardDetails[content].name}</h3>
                                        {cardDetails[content].body}
                                    </div>
                                }
                            </>
                    }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhatWeDo