import React from 'react';
 import logo from '../../assets/images/logo.png'

const CustomFooter = () => {

    return (
        <footer color="white">
            <div className='section-body'>
                <div className='container'>
                    <div className='footer footer-container'>
                        <div href="/" className='footer-brand'>
                            <img 
                                src={logo}
                                alt='pacdoc-sign'
                                className='footer-logo'
                            />
                        </div>
                        <div className='footer-text'>
                            <strong> Over One Million Signings Completed Nationwide Since 1990!</strong>
                            <p> Copyright © {new Date().getFullYear()} Pacific Document Signing Inc. All Rights Reserved. | Complaints | Privacy Policy </p> 
                        </div>
                        <div className='d-flex social-links'>
                            <a href="https://www.facebook.com/pacdocsign"><i className="fa fa-facebook social" /></a>
                            <a href="linkedin.com/pub/fred-simab/9/398/961"><i className="fa fa-linkedin social" /></a>
                            <a href="https://twitter.com/pacdocsign"><i className="fa fa-twitter social" /></a>
                            <a href="https://vimeo.com/pacdocsign"><i className="fa fa-vimeo social" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default CustomFooter