import React from 'react'
import {
    Row,
    Col,
} from 'reactstrap';
import Header from './common/Header';
import resware from '../assets/images/resware2.PNG';
import nna from '../assets/images/nna.jpeg';
import spw from '../assets/images/spw2.PNG';

const Partners = () => {

    return(
        <section  id='partners'>
            <Header name='Partners' />
            <div className='section-body'>
                <div className='container'>
                    <div className='inner-2'>
                        <Row className='container'>
                            <Col md="4" sm="4" xs="12" className='mt-4 mb-4'>
                                <div className="container-hover">
                                    <img src={resware} alt='resware' />
                                    <div className="overlay-partner">
                                        <a href='http://windwardrevolution.com/resware-solutions-overview/'>
                                            <div className="text">
                                                Resware Changes Everything <br />
                                                <strong>Visit Now</strong>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" sm="4" xs="12" className='mt-4 mb-4'>
                                <div className="container-hover">
                                    <img src={nna} alt='nna' />
                                    <div className="overlay-partner">
                                        <a href='https://www.nationalnotary.org/'>
                                            <div className="text">
                                                Mational Notary Association <br />
                                                <strong>Visit Now</strong>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" sm="4" xs="12" className='mt-4 mb-4'>
                                <div className="container-hover">
                                    <img src={spw} alt='spw' height='70%' />
                                    <div className="overlay-partner">
                                        <a href='https://signingprofessionalsworkgroup.org/'>
                                            <div className="text">
                                                Signing Professionals Workgroup<br />
                                                <strong>Visit Now</strong>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Partners