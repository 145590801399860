import React from 'react';
import Header from './common/Header';

const About = () => {

    return (
        <section id='about'>
            <Header name='About' />
            <div className='section-body'>
                <div className='container'>
                    <div className='inner content'>
                        <strong>
                            <h2 className='sub-head'>Welcome To PacDocSign</h2>
                        </strong>
                        <p>
                            The PacDocSign story began over two decades ago in Los Angeles, California. Our dedication to 
                            provide a loan document signing service that reflected the excellent reputation of our clients
                            had always been PacDocSign’s mission from the beginning. Originally launched as a notary signing
                            company, we saw the need for expedited loan document signing services and began to develop a 
                            systematic technique that would make the process more efficient and precise. As the need for 
                            our services grew, PacDocSign expanded its signing system throughout California, 
                            and eventually nationwide.
                        </p>
                        <p>
                            Over the last 20 years we’ve grown to share our services and expertise in all 50 states, with highly 
                            skilled agents blanketed across the country. Today our company is one of the nation’s largest and 
                            most trusted loan document signing service companies. Through customer feedback we continue to spearhead 
                            the document signing industry through consistency and reliability. With our continued growth, PacDocSign 
                            has expanded its services to include attorney witness only loan document signings. PacDocSign continues 
                            to revolutionize the way documents are signed and delivered.
                        </p>
                        <p>
                            Our operation is equipped to handle all of your company’s signing needs. From small companies to large 
                            volume lenders, title companies and vendor management companies, we extend the same level of professionalism
                            and expertise to all of our clients. Our reputation speaks for itself, and we continue to maintain a proven 
                            track record of security, reliability, and punctuality. When there are deadlines to be reached, when time and 
                            attention to detail are crucial factors, consider PacDocSign as the only solution.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About