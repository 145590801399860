import React from 'react'

const Header = ({ name }) => {

    return(
        <div className='section-head'> 
            <div className='container'>
                <h1>{name}</h1>
            </div>
        </div>
    )
}

export default Header